@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
.requestly-menu ul > li > a{
    /* font-family: 'Roboto', sans-serif; */
    font: 12px 'Roboto', sans-serif;
     color: #000000DE;
    font-weight: 500;
}


/* Main menu on hover*/
.requestly-menu ul > li > a:hover{
    background: #40506b !important;
    color: #fff;
}

/* button contained */
.MuiButton-contained {
    background : #40506b !important;
    }
.MuiButton-contained:hover {
    background : #ddd !important;
    color: #333 !important;
    }

/* Technical & Commercial Requirements Tabs */
.MuiTabs-indicator {
    background: #fff !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #fff !important;
}


/* button primary */
    .MuiButton-containedPrimary {
        background : #40506b !important;
        }
    
    /* button primary on hover */
    
    .MuiButton-containedPrimary:hover {
    background : #ddd !important;
    }
    /* button primary text color */
    .MuiButton-containedPrimary .MuiButton-label {
        color : #fff !important;
    }
    
    /* button primary text color  on hover*/
    .MuiButton-containedPrimary:hover .MuiButton-label {
        color : #000 !important;
    }

    .MuiButton-containedPrimary:disabled {
        background : #BBB !important;
    }

/* button secondary */
.MuiButton-containedSecondary {
    background : #40506b !important;
    }
    
    /* button secondary on hover */
    
    .MuiButton-containedSecondary:hover {
    background : #ddd !important;
    }
    /* button primary text color */
    .MuiButton-containedSecondary .MuiButton-label {
        color : #fff !important;
    }
    
    /* button primary text color  on hover*/
    .MuiButton-containedSecondary:hover .MuiButton-label {
        color : #000 !important;
    }


/* chips */ 
    .MuiChip-root {
    background-color:#ddd !important;
    }
    /* chips hover */ 
    .MuiChip-root:hover{
    background-color:green !important;
    }
    /* chips text inside */ 
    .MuiChip-root .MuiChip-label {
    color : #000 !important
    }
    
    /* chips text inside hover */ 
    .MuiChip-root:hover .MuiChip-label {
    color : #fff !important
    }

    /* chips cross button*/
.MuiChip-root .MuiChip-deleteIcon{
    color: #aaa !important;
    }
    
    /* chips cross button*/
    .MuiChip-root:hover .MuiChip-deleteIcon{
    color: #fff !important;
    }

.requestly-fin-info-tab {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.requestly-fin-info{
    margin-bottom: 0px !important;
    /* margin-top: 16px !important; */
    }


.requesly-active-menu {
    color: #4285f4 !important;
}

requestlyText {
    color: #F2564C;
    font-size: 20px;
    font-weight: 600;
}

.authSigninText {
    position: relative;
    padding: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.authRegisterText {
    position: relative;
    top: -10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

signinLinkText {
    padding-left: 5px;
    font-size: 16px;
    color: #40506b;
    font-weight: 600;
}

signupInfoText {
    font-size: 20px;
    color: #40506b;
    font-weight: 600;
}

.splitPage {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .mainImage {
      position: relative;
      width: 300px;
      height: 300px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .accountTitle {
      font-size: 30px;
      font-weight: 800;
      color: #F2564C;
  }

  .authInfo{
      position: relative;
      display: flex;
      flex-direction: column;
      width: 500px;
      align-items: center;
      vertical-align: middle;
      text-align: left;
  }

  .authForm{
    position: relative;
    display: flex;
    width: 50%;
    align-items: center;
    vertical-align: middle;
}

.rfp-setting-block{
width:55% !important;
margin :auto !important;
}
.rfp-breaccrumb{
margin: 0px !important;
padding: 0px !important;
font-size:18px !important;
margin-bottom:5px !important
}

.rfp-breaccrumb p{
    font-weight: bold !important;
    opacity : 0.5 !important;
    margin: 0px !important;
    
}
.requestly-card-text {
    font-size: 12px !important;
    margin: 44px auto 2px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.17 !important;
    letter-spacing: normal !important;
    font: 14px 'Roboto', sans-serif !important;
    color: #000 !important;
}
.makeStyles-TextPosition-27 {
    font-size: 12px;
}
.requestly-card-date {
    color: rgb(188, 188, 188);
    font-size: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    margin: 2px 0px;
    text-align: center;
}
.makeStyles-boxborder-24{
    text-align: center;
}
.requestly-card .MuiCardContent-root {
    padding: 0px 0px 36px;
}
.MuiTypography-h5{
    font-weight: 400;
}

.requestly-rfp-contain .requestly-rfp-container {
    max-width: 600px;
}
/* .requestly-rfp-form .MuiPaper-rounded{
    border-radius: 12px;
    box-shadow: 0px 1px 3px #00000066;
} */
.requestly-rfp-view {
    max-width: 720px;
    margin: 0px auto !important;
    width: 100%;
}

.rfp-breaccrumb{
margin-bottom:5px !important;
}
.requestly-tab-height {
    min-height: 30px !important;
    max-height: 30px;
    
}

.requestly-tab-height-sectionwise{
    min-height: 30px !important;
    max-height: 30px;
    background-color: #eeeeee !important;
}
.MuiTabScrollButton-root {
    opacity: 1;
    z-index: 9999;
    position: relative;
}
.categorySectionTabs{
    background : #fff;
    color:#333 !important;
    height:48px;
}
.categorySectionTabs .categorySectionTabs-2{
    color: white !important;
}
.requestly-text-box-in-tab{
    color:white !important;
    border-bottom:none !important;

}
.requestly-text-box-in-tab div{
    color:white !important;
    border-bottom:none !important;

}
.requestly-text-box-in-tab input{
    color:white !important;
    border-bottom:none !important;

}

.requestly-text-box-in-tab input:before{
    color:white !important;
    border-bottom:none !important;

}
.requestly-text-box-in-tab .MuiInput-underline::after {
    color:white !important;
    border-bottom:none !important;
}
.requestly-text-box-in-tab .MuiInput-underline::before {
    color:white !important;
    border-bottom:none !important;
}

.requestly-text-box-in-tab input:after{
    color:white !important;
    border-bottom:none !important;

}
.categorySectionTabs {
    background: #40506b !important;
    min-height: 40px !important;
    height: 40px;
}
.categorySectionTabs .categorySectionTabs-1 {
    background: #40506b !important;
    min-height: 40px;
    height: 40px;
}
.categorySectionTabs .MuiTab-labelIcon {
    min-height: 42px !important;
    height: 40px;
    padding-top: 3px;
}
.categorySectionTabs .MuiTab-labelIcon .MuiSvgIcon-root {
    margin-bottom: 0px !important;
    margin-top: 4px;
    font-size: 14px;
}
.requestly-tab-height-sectionwise .MuiIconButton-label .MuiSvgIcon-root {
    color: #111 !important;
}
.commercial-request fieldset {
    border: 0px !important;
}
 .MuiSvgIcon-fontSizeSmall {
    color: #fff !important;
}/*
.MuiTabScrollButton-root.Mui-disabled{
    opacity: 1;
    z-index: 99;
    position: relative;
} */
.question-score .MuiGrid-root {
    padding-left: 0px !important;
}
.delete-row-button {
    background-color: #fff !important;
    box-shadow: none !important;
    color: #bababa !important;
    text-align: left;
    min-width: auto !important;
}
.add-section-technical {
    display: block !important;
    margin: 10px auto 15px !important;
    background-color: #333 !important;
}
/* .went-passed{
border: 1px solid #bbb6b6 !important;
} */
.requestly-dashboard-inner-tabs button span {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    min-width: 120px;
}
.requestly-dashboard-tbody td {
    font-size: 10px !important;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    color: #000;
    letter-spacing: 0.5px;
}
.requestly-rfp-heading .MuiTypography-h5 {
        font-size: 24px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        float:left;
}
.requestly-rfp-heading .MuiCardHeader-subheader {
    float: right;
    width: calc(100% - 200px);
    color: #FFFFFFDE !important;
    font-size: 12px;
    text-align: right;
}
.requestly-stepper-step {
    max-width: 188px;
}
.requestly-rfp-textbox-dashboard {
    height: auto;
    background: #EEEEEE 0% 0% no-repeat padding-box;
    border-radius: 4px;
}
.requestly-rfp-textbox-dashboard .MuiOutlinedInput-input {
    padding: 5px 0px !important;
}
.requestly-dashboard-table-class thead tr th:nth-child(1) {
    background-color: #f4f6f8 !important;
    border:1px solid #f4f6f8 !important
}
.requestly-dashboard-table-outer-class tbody table thead tr th:nth-child(1){
    font-weight: bold;
    font-size: 16px;
    text-align: left
}
.requestly-dashboard-table-outer-class tbody table thead tr th {
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    padding: 4px 15px;
    text-align: center;
}
.requestly-dashboard-table-inner-class thead tr th:nth-child(1){
    font-size: 16px;
}
.requestly-dashboard-table-inner-class thead tr th {
    font-size: 12px;
    font-weight: normal;
    padding: 3px 15px;
    background-color: #eeeeee;
    color: #000;
}
.padding-requestly-Completion div {
    padding: 0px 0px;
    margin-top: 3px;
}
.commercial-request .MuiInputBase-input {
    padding: 13.5px 14px;
}
.radius-15 {
    border-radius: 15px !important;
}
.bg-white .makeStyles-acceptInviateHeader-18 {
    background-color: #fff !important;
    padding-bottom: 5px;
}
.requestly-rfp-textbox textarea{
    color:#707070;
    margin-left: 10px;
}
.requestly-black-button {
background-color: #000000 !important;
color: #fff !important;
font-size: 10px !important;
height: 35px;
}
.requestly-rfp-textbox .MuiOutlinedInput-multiline {
    padding: 10px 0px;
    height: auto;
    background: #EEE 0% 0% no-repeat padding-box;
    border-radius: 0px;
}
.pricing-textbox .MuiInputBase-formControl {
    background: #eee;
}
.pricing-textbox input {
    padding: 13.5px 14px;
    height: auto;
}
.pricing-textbox label {
    transform: translate(14px, 14px) scale(1);
}
.requestly-rfp-container .makeStyles-titleTagTxt-31 {
    font-size: 16px;
    font-weight: bold;
}
.requestly-rfp-textbox .MuiInputLabel-outlined{
    transform: translate(14px, 14px) scale(1);
    border-color: #3bbb49;
}
.requestly-disabled-label {
    color: #BBB;
    font-family: 'Roboto', sans-serif;
}
.supporting-heading {
    font-weight: 500 !important;
    padding: 8px;
    font-size: 15px !important;
}
.bg-gray {
    background-color: #eee;
}
.mb-2.Presentation-Agenda-table .MuiToolbar-regular {
    margin-bottom: 15px;
}
.vendor-invite-tabel table {
    border:1px solid #eee;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}
.label-title {
    color: #b2b2b2;
    font-size: 12px !important;
    margin-top: 5px !important;
    font-weight: 400 !important;
}

.requestly-tooltip {
    background-color: #40506b;
}

.div-heading p {
    color: #000;
    font-weight: 500;
}
.requestly-rfp-button {
    width: 108px;
    height: 36px;
    border-radius: 6px;
    padding:0px !important;
}
.requestly-rfp-contain .MuiBox-root-43 {
    padding: 9px 15px 6px;
}
.requestly-rfp-form .MuiList-padding {
    padding-top: 2px;
    padding-bottom: 2px;
}
.footer-btn-fixed {
    position: fixed;
    bottom: 0px;
    z-index: 9999;
    width: 100%;
    left: 0px;
    border: solid 1px #eeeeee;
    background-color: #ffffff;
    height: 51px;
    padding: 8px 23px 7px 343px;
}
.footer-btn-fixed button {
    width: 50%;
    height: 36px;
    border-radius: 6px;
}
.company-information .requestly-rfp-heading .MuiCardHeader-subheader{
    width: calc(100% - 280px);
}
.fixed-radio-class span {
padding-left:0px;
padding-right:2px;
}
.company-information .p-0 {
    padding: 0px;
}
.company-information .company-info-div {
    max-width: 720px;
    margin: 0px auto;
    margin-bottom:10px !important;
}
.requestly-bg-gray {
    background: #eeeeee;
    color:#707070 !important;
}
.requestly-bg-gray div {
    color:#707070 !important;
}
.requestly-bg-gray .WAMuiChipInput-chipContainer-54{
    margin-bottom: 0px;
}
.requestly-h-40 div {
height:40px;
}
.requestly-h-46 div{
height:46px;
}
.requestly-h-40 label{
    transform: translate(14px, 13px) scale(1);
}
.MuiChip-clickable:hover, .MuiChip-clickable:focus {
    background-color: #40506b !important;
    color: #fff !important;
}


/* input border colour */
.MuiTextField-root .Mui-focused {
    color: #40506b !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border:2px #40506b solid !important;
}



.MuiFormLabel-root.Mui-focused{
    color:#40506b !important;
    }

.multiselect-textbox .MuiChip-root {
    background-color: #E0E0E0;
    font-size: 14px;
    color: #000;
}

.terms-style .MuiDialog-paper {
    width: 600px !important
}

.non-disclousre {
    margin: 0px auto;
}
.requestly-rfp-heading.width-auto .MuiCardHeader-subheader {
    width: auto;
    margin-top: 6px;
}
.non-custom-label span + span {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}
.non-custom-label label span {
    padding: 2px 9px;
}
.file-upload-non .MuiDropzoneArea-root {
    min-height: 152px;
    margin: 0 0 15px;
    padding: 26px 30px 22.8px;
    border-radius: 5px;
    border: solid 1px #a2c3f7;
    background-color: #ffffff;
}
.file-upload-non p {
    margin: 4px 0 9px;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
}
.MuiSvgIcon-root.MuiDropzoneArea-icon {
    width: 24px;
    height: 16px;
    opacity: 0.54;
}


#google-map-demo{
padding-top:0px;
}
.company-information .profilePics.img-thumbnail {
    border-radius: 6px;
    /*object-fit: cover;*/
    object-fit:contain;
    height: 120px;
    width: 140px;
}
.company-information .rich-text__editor.rdw-editor-main {
    background: #eee;
    border-radius: 4px;
}
.company-information .MuiGrid-root label{
    display: block;
}
.requestly-positon {
    position: relative;
    width: 100%;
}
.requestly-stepper {
    width: 100%;
    position: absolute;
    top: 29%;
    transform: translate(-0% , -50%);
    padding: 5px 15px 0px !important;
}
.undefinedmarginLeftSet {
    margin-left: 19%;
}
.requestly-stepper-step .requestly-span .MuiTypography-body2 {
    margin: 3.5px 6px 3.5px 5px;
    font: 14px 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000000 !important;
}
.requestly-positon .requestly-stepper {
    /*position: fixed;
    width: 247px;
    max-width:100%;
    top: 50%;
    transform: translate(0px, -50%);*/
position: fixed;
max-width: 247px;
width: 195px;
top: 50%;
transform: translate(0px, -50%);
}
.requstly-pragraph-css p {
    margin: 9px 8px 10px 6px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    font-family:'Roboto', sans-serif !important;
}
.requstly-recomand-heading h4 {
    margin: 24px 11px 12px 2px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    font-family:'Roboto', sans-serif !important;
}
.requstly-custom-label span {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.25;
    letter-spacing: normal;
    text-align: left;
    color: #aaaaaa;
}
.requstly-custom-label span svg{
   color: #4688f0;
}

.non-disclousre .MuiPaper-rounded {
    border-radius: 12px;
}
.company-info-div .MuiCard-root {
    border-radius: 15px;
}
.border-remove .MuiPaper-elevation2 {
    box-shadow: none;
}
.final-content-custum{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}
.final-custome-radio span.MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}
.Presentation-Agenda-table .MuiPaper-rounded {
    border-radius: 0px;
    box-shadow: none;
}
.Presentation-Agenda-table table thead th {
    height: 14px;
    margin: 0 40px 0 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    background-color: #40506b;
    padding: 3px 8px;
}
.Presentation-Agenda-table table td {
    height: 25px;
    padding: 7px 7px 4px 5px;
    background-color: #eeeeee;
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #555555 !important;
}
.Presentation-Agenda-table table td:nth-child(1) {
    color: #000000 !important;
    font-size: 12px !important;
}
.Presentation-Agenda-table .MuiToolbar-gutters {
    padding-left: 0px;
    min-height: auto;
}
.Presentation-Agenda-table .MuiToolbar-gutters h6{
    font-size: 15px;
    font-weight: 600;
}
.Presentation-Agenda-table table td:nth-child(3n) {
    color: #000000 !important;
}
.rich-text__editor.rdw-editor-main:hover {
    border-color: #263238;
}
.rich-text__editor:focus {
    border-width: 2px;
    border-color: #4688f0;
}
.Presentation-Agenda-table table td span svg{
    font-size: 12px;
}
.Presentation-Agenda-table table td button{
    padding: 8px;
}
.categorySectionTabs-2 {
    border-right: 1px solid #fff !important;
}
.thumb-align-right{
text-align:right !important;
}
.wrap-me{
white-space: pre-wrap;      /* CSS3 */
   white-space: -moz-pre-wrap; /* Firefox */
   white-space: -pre-wrap;     /* Opera <7 */
   white-space: -o-pre-wrap;   /* Opera 7 */
   word-wrap: break-word;      /* IE */
}

.wrap-me-strict{
white-space: pre-wrap;      /* CSS3 */
   white-space: -moz-pre-wrap; /* Firefox */
   white-space: -pre-wrap;     /* Opera <7 */
   white-space: -o-pre-wrap;   /* Opera 7 */
   word-wrap: break-word;
word-break: break-all;
}

.ellipse-text{
overflow: hidden;
text-overflow: ellipsis
}

.MuiChip-deletable{
overflow: hidden !important;
text-overflow: ellipsis !important;
}

.small-stepper-submit .MuiStepConnector-vertical{
padding:0px !important;
}

.small-stepper-submit .MuiStepConnector-vertical .MuiStepConnector-lineVertical{
min-height:20px !important;
}

.small-stepper-submit .requestly-stepper-step .MuiStepButton-vertical{
padding-top:0px !important;
padding-bottom:0px !important;

}

.timeline-start .timeline-badge {
background : #3bbb49 !important
}

.timeline-end .timeline-badge {
background : #bb3b3b !important
}

.required:after {
    content:" *";
    color: rgb(195, 203, 206);
  }

.logo-clickable{
    cursor:pointer;
}
.logo-clickable:hover{
    border:2px solid #4688f0;
}

@media screen and (min-width: 768px) {
    
    .makeStyles-boxborder-24{
        width: 50% !important;
    }
  }

.MuiSnackbar-anchorOriginBottomLeft{
    z-index:10000 !important;
}

.mb-4{
margin-bottom:10px !important;
}

.no-shadow{
box-shadow:none !important;
}

.no-margin{
margin : 0px !important
}

.pwa-selected-tab {
    background: #aaa !important;
}

.algolia-cat-block{
    height: 220px;
    overflow: auto;
}

.p-d-8{
padding-left:8% !important;
}

/*@media screen and (max-width: 1920px) {
    .p-d-8{
padding-left:12% !important;
}
  }*/

.MuiAccordionSummary-root p {
    font-size: 16px !important;
}

.site-delete-icon{
color:#a0a0a0 !important;
cursor: pointer !important;
width:18px !important;
height:18px !important;
}
.MuiDropzoneSnackbar-successAlert{
            background-color: #559cf9 !important;
            border-color:#4493f7 !important;
}


@media only screen and (max-width: 1024px) {

.splitPage {
    width:100%;
    top:170px;
    flex-direction: column;
    background-color: #fff;
  }
.authSigninText {
    position: relative;
    padding: 20px;
    background-color: #fff;
}
.mainImage {
    margin-bottom: 0px;
    position: relative;
    width: 200px;
}

.authInfo{
    width: 400px;
    font-size: 12px;
}

.request-pd-card{
    /* color: #000000 !important; */
font-weight:bold !important;
    background: "rgb(76,188,105)"   !important;
}

.company-block-card{
max-width:103px !important;
}

.requestly-rfp-form.popup2-form {
    max-width: 590px;
}
.popup2-form .popup2-card .popup2-header .MuiCardHeader-title {
    font-size: 22px;
    font-weight: 600;
}

.popup2-form .popup2-card .popup2-answer {
    margin-bottom: 20px !important;
}
.popup2-form .popup2-card .popup2-question {
    margin-top: 1rem;
}

.popup2-button-accept {
    float: right;
}
.popup2-card-content .MuiGrid-root.MuiGrid-container {
    margin-top: 0px !important;
}
.popup2-textfield {
    border-radius: 4px;
    background-color: #eee;
}

.requestly-rfp-contain .MuiContainer-root {
    padding-left: 0 !important;
  }

.authForm{
    width: 100%;
}

}
