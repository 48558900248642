* {
  font-family: "Spartan", sans-serif !important;
}
.insideBox {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px 20px;
  text-align: center;
  overflow: hidden;
  width: 100%;
}
.tabFeature {
  margin: 0 -20px 30px;
  padding: 0;
}
.tabData {
  font-family: "Spartan", sans-serif !important;
  padding: 12px 20px;
  list-style: none;
  border-bottom: 1px solid #f1f1f1;
  font-size: 12px !important;
}
.tabbutton {
  margin: 30px -20px 0;
  padding: 8px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  padding-top: 30px;
}
.tabbutton .btn-mod {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100% !important;
  padding: 7px 17px 8px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #fff;
  background: rgba(17, 17, 17, 0.9);
  border: 2px solid transparent;
}
