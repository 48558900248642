.asSeenOn{
  position: relative;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.asSeenOnTitle {
  position: relative;
  max-height: 75px;
  font-size: 16px;
  font-weight: 700;
  color: #40506b;
}

.asSeenOnLogo {
  position: relative;
  max-height: 40px;
}

@media only screen and (max-width: 844px) {
  .asSeenOnLogo {
    max-height: 30px;
  }
}