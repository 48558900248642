.Toastify .Toastify__close-button {
  display: none;
}
.Toastify .Toastify__toast-container {
  text-align: center;
}
.Toastify .Toastify__toast--info {
  background: #000000;
  border-radius: 4px;
  font-weight: bold;
}
.Toastify .Toastify__toast--success {
  background: #1fc130;
  border-radius: 4px;
  font-weight: bold;
}
.Toastify .Toastify__toast--error {
  background: #f44336;
  border-radius: 4px;
  font-weight: bold;
  color: white;
  font-size: 12px;
}
