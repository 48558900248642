.rich-text {

    flex-direction: column-reverse;
    display: flex;
    color:black !important;

    &__editor {
        padding: 10px;
        border: 1px solid lightgrey;
    }
    .rdw-option-wrapper[title="Remove"],
    .rdw-colorpicker-wrapper {
        display: none;
    }
}