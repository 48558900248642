
.messageBOX {
        position: absolute;
        top: 20%;
        left: 48%;
        transform: translate(-25%, -50%);
        z-index: 1000000;
    .alert {
color: #fff;font-weight: 500;padding: 6px 16px;box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);line-height: 1.43;border-radius: 4px;letter-spacing: 0.01071em;
        .close {float: right;font-size: 24px;font-weight: 600;line-height: 1;color: #ffffff;text-shadow: 0 1px 0 #ffffff;opacity: 1;padding: 0px 4px 4px 4px;margin-left: 5px;border-radius: 25px;cursor: pointer;}
    }
    .alert-success {
            background-color: #559cf9;
            border-color:#4493f7;
            padding:16px;
            }
    .alert-danger {
        background-color: #f44336;
        border-color:#f44336;
        padding:16px;
        }
}


