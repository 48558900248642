// Common CSS
.makeStyles-width100-20 {width: 100% !important;}

// Company Info & Product Info
.MuiTypography-colorTextSecondary {
    color: white !important;
    float: right;
}
.MuiAccordion-root {
    .MuiTypography-body1 {width: 100% !important;}
}
.MuiAccordionSummary-root {background-color: #40506b !important;color: #fff !important;margin-top:1px !important;
    p {font-weight: 600 !important;font-size: 16px !important;}
}
.makeStyles-companyHeadings-11, .makeStyles-companyHeadings-15, .makeStyles-companyHeadings-18, .makeStyles-companyHeadings-16, .makeStyles-companyHeadings-21, .makeStyles-companyHeadings-51 {
    .MuiCardHeader-content {
        .MuiTypography-displayBlock {display: inline !important;}
        .MuiTypography-colorTextSecondary {font-size: 12px !important;}
    }
}
.makeStyles-customInputs-14 {
    .MuiGrid-item {display: inline-flex;width: 100%;padding: 1%;
        input {margin-right: 10px;}
        .MuiButton-root {margin: 10px;padding:4px 8px;font-size: 12px;
            &.makeStyles-addPlus-15 {background-color: #015a01  !important;}
            &.makeStyles-addMinus-16 {background-color: #40506b  !important;}
        }
    }
}
#panel-location-content, #panel-upload-content, #panel-pricing-content, #panel-Pricingss  {
    .MuiGrid-item {display: inline-flex;width: 100%;padding: 1%;
        input {
            // height:0px;
            margin-right: 10px;}
        .MuiButton-root {margin: 10px;padding:4px 8px;font-size: 12px;
            // &.MuiButton-containedSecondary {background-color: #015a01  !important;}
            &.MuiButton-containedPrimary {background-color: #40506b  !important;}
        }
    }
}

#panel-upload-content{
    .MuiGrid-item {display: inline-flex;width: 100%;padding: 1%;
        input {
            height:0px;
            margin-right: 0px;}
        .MuiButton-root {margin: 10px;padding:4px 8px;font-size: 12px;
            // &.MuiButton-containedSecondary {background-color: #015a01  !important;}
            &.MuiButton-containedPrimary {background-color: #40506b  !important;}
        }
    }
}

 #panel-pricing-content {
    .MuiGrid-item {display: inline-flex;width: 100%;padding: 1%;
        input {margin-right: -2px;}
        .MuiButton-root {margin: 10px;padding:4px 8px;font-size: 12px;
            // &.MuiButton-containedSecondary {background-color: #015a01  !important;}
            &.MuiButton-containedPrimary {background-color: #40506b  !important;}
        }
    }
}



.makeStyles-profileImg-17, .makeStyles-profileImg-24 {text-align: center; height: 155px; padding: 0 !important;
    input[type="file"] {display: none; }
    .custom-file-upload {border: 1px solid #4688f0;background-color: #4688f0;background-image: linear-gradient(0deg, #040f4c, #4688f0, #041365, #1c39de);color: #fff;font-size: 12px;display: inline-block;padding: 4px 8px;cursor: pointer;border-radius: 20px;margin-top: 5px;}
    img {border-radius: 50%;height: 120px;width: 120px;box-shadow: 0px 1px 5px #757575;}
}
.custom-file-upload {border: 1px solid #4688f0;background-color: #4688f0;background-image: linear-gradient(0deg, #040f4c, #4688f0, #041365, #1c39de);color: #fff;font-size: 12px;display: inline-block;padding: 4px 8px;cursor: pointer;border-radius: 20px;margin-top: 5px;}
.profilePics {border-radius: 50%; height: 120px; width: 120px; border: 2px solid #707070 ;padding: 0;}

// Create RFP
.makeStyles-titleTag-16 {
    .MuiTypography-colorTextPrimary {font-size: 18px;font-weight: 800;margin: auto;}
}
.makeStyles-inputAll-17 {
    .makeStyles-item-13 {text-align: center;
         .MuiFormControlLabel-root {//display: block;margin-bottom: 20px;
            .MuiTypography-body1 {display: block;}
        }
    }
    .MuiFormGroup-root {display: contents !important;}
}
.makeStyles-listChecks-18 {margin-top: 15px;
    .MuiFormControlLabel-root {display: block;
    }
}
.makeStyles-footerBox-19 {
    .MuiButton-root {float: right;}
}


// Update RFP (Combined with other classes above)
.makeStyles-myParagraphs-21 {
    .MuiTypography-subtitle1 {font-size: 14px;}
    .MuiTypography-h5 {line-height: 2.5;}
}
.iconSlides {
    .MuiTab-labelIcon {min-height: 55px !important;}
    .MuiTabs-scroller {
        .MuiTab-wrapper {display: inline !important;
            svg {float: right; color: #40506b;}
        }
    }
    .MuiAccordionSummary-root {
        svg {float: right; }
    }
}

#questionAns {display: inline-block;width: 100%;
    label {margin-bottom: 0;
        span {padding: 0 5px;};
    }
}
#perSclass, #perSclass1, #perSclass2, #perSclass3,#perSclass4, #perSclass5 {
    p {color: #000 !important;}
}
#gbtnL {
    button {width: 100%;}
}
.MuiAccordion-root {
    .MuiAccordionSummary-expandIcon {
        span {
            &.MuiIconButton-label {
                svg {color: #fff;}
            }
        }
    }
}
#dateFnt {
    input {font-size: 12px;}
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {border-width: 2px !important;}
#acrdInputs {
    .MuiInput-underline:before {
        border: none;
    }
    .MuiInput-underline:after {
        border-color: #40506b !important;
    }
}
#orRow {align-items: center; color: #ccc; display: flex; margin: 25px 0;
    .lineSeparators {background-color: #ccc;flex-grow: 5; height: 1px;}
    .orTxt {flex-grow: 1;margin: 0 15px;  text-align: center;}
}



/* Timeline */
.timeline, .timeline-horizontal { list-style: none; padding: 20px; position: relative; }
.timeline:before {top: 40px;bottom: 0;position: absolute; content: " "; width: 3px; background-color: #ccc; left: 50%; margin-left: -1.5px;}
.timeline .timeline-item {margin-bottom: 20px;  position: relative;}
.timeline .timeline-item:before,.timeline .timeline-item:after { content: "";  display: table;}
.timeline .timeline-item:after {clear: both;}
.timeline .timeline-item .timeline-badge {color: #fff; width: 15px; height: 15px; position: absolute; top: 16px; left: 50%; margin-left: -25px; background-color: #ccc; border: 1px solid #ffffff; z-index: 100; border-top-right-radius: 50%; border-top-left-radius: 50%;
  border-bottom-right-radius: 50%; border-bottom-left-radius: 50%;}
.timeline .timeline-item .timeline-panel { position: relative; width: 46%; float: left; right: 16px; background: transparent;border-radius: 2px; padding: 0px;}
.timeline .timeline-item .timeline-panel .timeline-title {margin-top: 0;color: inherit;}
.timeline .timeline-item .timeline-panel .timeline-body > p, .timeline .timeline-item .timeline-panel .timeline-body > ul {margin-bottom: 0;}
.timeline .timeline-item .timeline-panel .timeline-body > p + p {margin-top: 5px;}
.timeline .timeline-item:last-child:nth-child(even) {float: right;}
.timeline-horizontal {list-style: none;position: relative;padding: 20px 0px 20px 0px;display: inline-block;margin-top: 50px;margin-bottom: 63px;width: 98%;}
.timeline-horizontal:before {height: 1px; top: auto; bottom: 20px; left: 20px; right: 0; width: 100%; margin-bottom: 10px;}
.timeline-horizontal .timeline-item {display: table-cell; height: auto; width: 1%; min-width: 70px; float: none !important; padding-left: 0px; padding-right: 20px; margin: 0 auto; vertical-align: bottom;}
.timeline-horizontal .timeline-item .timeline-panel {top: auto;bottom: 30px;display: inline-block;float: none !important;left: 15px !important; right: 0 !important; width: 100%;margin-bottom: 5px; max-width: 60px; font-size: 12px;}
.timeline-horizontal .timeline-item .timeline-panel.belows {bottom: inherit; top: 95px;}
.timeline-horizontal .timeline-item:before,.timeline-horizontal .timeline-item:after {display: none;}
.timeline-horizontal .timeline-item .timeline-badge {top: auto; bottom: 3px;left: 43px;}

/* Timeline next */
.timeline .timeline-item .timeline-badge.redis {background-color: #ec533a;}
.timeline .timeline-item .timeline-panel p.redisColors {color: #ec533a;}
.timeline-horizontal.next {margin-top: 25px;margin-bottom: 20px; padding: 20px 0 1px 0;}
.timeline-horizontal.next:before {display: none;}
.timeline-horizontal.next .timeline-item {min-width: inherit;width: 1%;}
#dataTblBox {
    th, td {border: 1px solid #e8e8e8;}
}
.MuiPaper-elevation4 { box-shadow: none !important }

.field-error{
    color: #F2564C;
  }
  .makeStyles-drawerClose-16 .sidebar_heading h3
{
    padding: 15px 0; font-size: 12px;
}
.sidebar-custom-false h3 {
    padding: 15px 0; font-size: 12px;
}
  .makeStyles-drawerClose-16 .tit_hd {
    position: absolute;
    opacity: 0;
}
.custom-drawer-false .tit_hd {
    position: absolute;
    opacity: 0;
}
.main-wrapper{max-width: 100% !important;}
.sd_stepper {
    background: #e0e0e0 !important;
    margin: -50px 0 -50px -24px !important;
    box-shadow: 2px 0 6px #00000033;
    min-height: calc(100vh + 100px);
}
.ins_stepper{background-color: transparent !important; margin-bottom:70px !important}
@media (max-width: 767px) {
    .sd_stepper {
        margin: 0 0 25px !important;
        min-height: auto;
    }
}
/*  ------------ @ Media ------------ */
/*  Desktops, 1281px to higher resolution desktops */
@media (min-width: 1281px) {
    #rightContain {padding-left: 0;}#rightContain {
        p {
            &.MuiTypography-body1 {width: 100% !important;}
        }
    }
    .makeStyles-custWidthRFP-17 {max-width: 100% !important;margin: 0 !important;} 
}
/* Laptops, Desktops, ##Screen = B/w 1025px to 1280px */
@media (min-width: 1025px) and (max-width: 1280px) {
    #rightContain {padding-left: 0;}#rightContain {
        p {
            &.MuiTypography-body1 {width: 100% !important;}
        }
    }
    .makeStyles-custWidthRFP-17 {max-width: 100% !important;margin: 0 !important;} 
}
/* Tablets, Ipads (portrait), ##Screen = B/w 768px to 1024px  */
@media (min-width: 768px) and (max-width: 1024px) {
	 
}
/* Tablets, Ipads (landscape), ##Screen = B/w 768px to 1024px  */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}
/* Low Resolution Tablets, Mobiles (Landscape),  ##Screen = B/w 481px to 767px  */
@media (min-width: 481px) and (max-width: 767px) {
    
}
/*   Most of the Smartphones Mobiles (Portrait),  ##Screen = B/w 320px to 479px  */
@media (min-width: 320px) and (max-width: 480px) {
    
}

// .MuiCard-root{
//     overflow: auto !important;
// }

.MuiDropzoneArea-invalid {
    background-image: repeating-linear-gradient(-45deg, #fff, #fff 25px, rgba(0, 0, 0, 0.12) 25px, rgba(0, 0, 0, 0.12) 50px)!important;
    border-color: unset!important;
  }
  .MuiCardActions-root.MuiCardActions-spacing {
    /* background: #C0C0C0; */
    background:"#e3ecf6"

}


