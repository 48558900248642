//search query
.searchQueryContainer {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.10) 0px 1px 5px;
}

.queryResultsCount {
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
}

.searchResultsContiner {
}

//Navigation Panel
.pageContainer {
  margin: 0em;
  background-color: #eff0f6;
}
.contentContainer {
display: flex;
flex-direction: row;
}

.navContainer {
  background-color: #fff;
  width: 25%;
  min-width: 340px;
  max-width: 350px;
  padding: 30px 10px 10px 10px;
  margin-right: 0px;
  
}
.resultsContainer {
  width: 75%;
  padding: 0px;
  margin: 20px;
}

.navHeader {
  position: fixed;
background-color: #F2564C;
}

.navListStyle {
  width: 100%;
  max-height: 260px;
  overflow: scroll;
  overflow-x: hidden;
  border-radius: 10px;
  margin-bottom: 30px;
}
.navCheckBoxStyleList{
  
}

.listItemCategory {
  width: 100%;
  height: 35px;
  display: -webkit-box;
}
.navItem {
  width: 290px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ddd;
  padding: 2px;
  border: 1px solid #fff;
  border-radius: 3px;
  transition: 0.3s;
}

.navItem:hover {
  border: 1px solid #40506b;
}
.navText {
  width: 80%;
  font-size: 12px;
  padding-left: 10px;
  color: #40506b;
}
.navCount {
  font-size: 10px;
  padding:3px;
  min-width: 20px;
  margin-left: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  background-color: #5d759c;
}
// Chips
.requestlyChip {
  background: #40506b;
  color: #ddd;
  border-radius: 15px;
  font-size: 12px;
  padding: 5px;
  margin: 5px;
  vertical-align: middle;
}

//category page info
.categoryPageTitle {
  margin-top: 20px;
  color: #40506b;
  font-size: 40px;
  font-weight: 700;
}

.categoryDescription {
  width: 100%;
  margin: 10px 0px 10px 0px;
  color: #40506b;
  font-size: 14px;
  font-weight: 400;
}
.categoryInfoContainer {
  position: relative;
  flex-direction: column;
}

.categoryContent {
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: 290px;
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: row;
}
.kpiInfo {
  display: flex;
  align-items: center;
  top: -1px;
  height: 40px;
  padding: 10px;
  background-color: #40506b;
  color: #fff;
  border-radius: 0px 0px 10px 10px;
  font-size: 12px;
  font-weight: 700;
}
.categoryVideo {
  display: flex;
  align-items: center;
  width: 517px;
  height: 290px;
}
.categoryVendors {
  display: flex;
  flex-direction: column;
  padding: 5px 0px 20px 20px;
  color: #40506b;
  font-size: 14px;
  font-weight: 700;
}

.vendorTitle {
  padding: 0px;
  margin: 0px;
  color: #F2564C;
  font-size: 14px;
  font-weight: 700;
}
.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: contain;
  text-align: center;
}

.videoiFrame {
  height: 289px;
  width: 514px;
}

.line {
  margin: 10px;
}

.resultsListItem {
  margin-bottom: 15px;
  position: relative;
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #fff;
  cursor: pointer;
  transition: 0.3s;
}

.resultsListItem:hover {
  box-shadow: rgba(50, 50, 93, 0.15) 0px 4px 10px -1px, rgba(209, 182, 182, 0.2) 0px 2px 8px -1px;
}
.productDetail {
  width: 78%;
  height: 120px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.productLogo {
  padding: 5px;
  width: 135px;
  overflow: hidden;
}
.mobileTitle {
  display: flex;
  flex-direction: row;
}
.productLogoImageMobile {
  visibility: hidden;
  clear: both;
  display: none;
}
.noLogoMobile {
  visibility: hidden;
  clear: both;
  display: none;
}
.productLogoImage {
  height: 90px;
  width: 90px;
  margin-right: 10px;
  border-radius: 50%;
  background-size: cover;
  object-fit: contain;
}
.noLogo {
  height: 90px;
  width: 90px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: contain;
  background-color: #40506b;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}
.productInfo {
  width: 100%;

}
.productTitle {
  font-size: 16px;
  font-weight: 700;
  color: #40506b
  ;
}
.productDescription {
  font-size: 12px;
}
.productCategory {
  padding-right: 10 px;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-weight: 700;
  justify-content: flex-end;
}
.productCategoryChip {
  margin-right: 5px;
  padding: 3px;
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #ddd;
  color: #40506b;  
}
.productCategoryChip:hover {
  background-color: #40506b;
  color: #fff;
}
.productSubCategoryChip {
  padding: 3px;
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #ddd;
  color:#40506b;
}
.productSubCategoryChip:hover {
  background-color: #40506b;
  color: #fff;
}
.companyDetail {
  width: 22%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.companyDetailsPtag {
  margin: 0px;
  padding: 0px;
}

.companyTitle {
  height: 30px;
  font-size: 12px;
  font-weight: 700;
}
.companyInfo {
  position: relative;
  height: 25px;
  font-size: 10px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 1366px) {

  .resultsListItem {
    height: 140px;
  }
  .productDetail {
    overflow: visible;
  }

}
@media screen and (max-width: 667px) {

  .categoryPageTitle {
    margin-top: 10px;
    color: #40506b;
    font-size: 25px;
    font-weight: 700;
  }

  .resultsListItem {
    flex-direction: column;
    height: 180px;
  }
  .categoryContent {
    overflow: hidden;
    height: auto;
    flex-direction: column;
  }

  .kpiInfo {
    display: flex;
    flex-wrap: wrap;
  }

  .productDetail {
    width: 100%;
    overflow: visible;
  }
  .productTitle {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    height: 20px;
    font-size: 12px;
  }
  .productDescription {
    font-size: 10px;
  }
  .companyTitle {
    visibility: hidden;
    clear: both;
    display: none;
  }

  .companyDetail {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px;
  }
  .productLogo {
    visibility: hidden;
    clear: both;
    display: none;
  }
  .noLogo {
    visibility: hidden;
    clear: both;
    display: none;
  }
  .productLogoImageMobile {
    visibility: visible;
    clear: none;
    display: inline;
    height: 25px;
    width: 25px;
    margin-right: 1px;
    border-radius: 50%;
    background-size: cover;
    object-fit: contain;
  }
  .noLogoMobile {
    visibility: visible;
    height: 25px;
    width: 25px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: contain;
    background-color: #40506b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
  }
  .productCategory {
    justify-content: space-around;
  }
  .productCategoryChip {
    font-size: 8px;
    font-weight: 300;
  }
  .productSubCategoryChip {
    font-size: 8px;
    font-weight: 300;
  }
  .navContainer {
    visibility: hidden;
    clear: both;
    display: none;
  }
  .resultsContainer {
    width: 95%;
    padding: 0px;
    margin: 10px;
  }

  .categoryTitle {
    margin-top: 20px;
     font-size: 14px;
    font-weight: 700;
  }
  .categoryDescription {
    font-size: 10px;
    font-weight: 400;
  }
  .categoryInfoContainer {
    width: 100%;
    flex-direction: column;
    height: auto;
  }
  .categoryVideo {
    width: 100%;
    border: 0px;
  }
  .vendorTitle {
    font-size: 12px;
  }
  .categoryVendors {
    padding: 5px;
    font-size: 12px;
    font-weight: 700;
  }
}