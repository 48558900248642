@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Spartan', sans-serif!important;
}
.face {
    margin-left: -5%;
    color: #000;
}
.face:after {
    // content: '\1F50E';
}

.searchBarProduct .MuiAutocomplete-popper {
    width: 30vW!important;
    max-width: 30vW!important;
    top: -4px!important;
    left: -20.5%!important;
}

.MuiAvatar-circle {
    border-radius: 0!important;
}
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
  }
  .pagination > li {
    display: inline;
    color: #aaa;
  }
  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    padding: 7px 15px;
    margin: 5px;
    border-radius: 5px;
    font-size: 14px;    
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {

    
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
 
  }
  .pagination > li > a:hover,
  .pagination > li > span:hover,
  .pagination > li > a:focus,
  .pagination > li > span:focus {
    z-index: 2;
    margin: 5px;
    border-radius: 5px;
    transition: 0.3s;
    color: #000;
    background: #ddd;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    z-index: 3;
    margin: 0px;
    border-radius: 5px;
    border: 2px solid #eee;
    background: #fff;
    color: #40506b;
    cursor: default;
    font-size: 14px;
    font-weight: 700;
  }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    visibility: hidden;
    clear: both;
    display: none;
  }
  .pagination-lg > li > a,
  .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
  }
  .pagination-lg > li:first-child > a,
  .pagination-lg > li:first-child > span {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .pagination-lg > li:last-child > a,
  .pagination-lg > li:last-child > span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .pagination-sm > li > a,
  .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
  .pagination-sm > li:first-child > a,
  .pagination-sm > li:first-child > span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .pagination-sm > li:last-child > a,
  .pagination-sm > li:last-child > span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .companyName {
    max-width: 300px;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 700;
    color: #40506b;
    cursor: pointer;
  }