.owner {
  position: relative;
  margin: 0px 10px 10px 0px;
  display: block;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  color: #777;
  font-size: 10px;
  font-weight: 700;
}

.container-footer {
  width: 100%;
  padding-bottom: 20px;
  background-color: #F2564C;
  color: #fff;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
}

.footer-social-links {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.footer-text {
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.icon-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.icon-circle {
  position: relative;
  width: 54px;
  height: 54px;
  margin: 5px;
  background-color: #fff;
  color: #000;
  border-radius: 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-holder {
  display: block;
  align-items: center;
}

.icon-style {
  position: relative;
  height: 16px;
}